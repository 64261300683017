
.cursor-dot,
.cursor-dot-outline {
  z-index: 999;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  mix-blend-mode:difference;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  background-color: var(--color-cursor);

}

.cursor-dot {
  width: 8px;
  height: 8px;
}





.cursor-dot-outline {
  width: 40px;
  height: 40px;
}