.home {
  
  &__intro {
    margin-top: 64px;
    @media (max-width: 620px) {
      margin-top: 32px;
    }
  }

  &__links {
    display: flex;
    margin-right: 32px;
    list-style: none;

    li {
      margin-right: 8px;
      @media only screen and (max-width: 620px) {
        margin-right: 4px;
      }
    }

    @media (max-width: 620px) {
      margin-right: 24px;
    }
  }
  &__links_list {
    display: flex;
    margin-top: 64px;
    @media (max-width: 620px) {
      justify-content: space-between;
      margin-top: 32px;
    }
  }
  &__projects_featured {
    margin-top: 32px;
  }
}
