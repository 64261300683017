@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&display=swap");

@font-face {
  font-family: "MonoLisa-Regular";
  src: local("./assets/fonts/monolisa-regular.woff2") format("woff2");
}
@font-face {
  font-family: "MonoLisa-Bold";
  src: local("./assets/fonts/monolisa-bold.ttf") format("ttf");
  font-weight: bold;
  font-style: bold;
}

:root {
  --font-default: "Fira Code", monospace;
  --font-regular: "MonoLisa-Regular", "Fira Code", monospace;
  --font-bold: "MonoLisa-Bold", "Fira Code", monospace;
  --color-section: #0032e44d;
}

::selection {
  background-color: var(--color-section);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  cursor: none;
}

html {
  font-family: var(--font-regular, --font-default);
  font-display: auto;
  line-height: 1.9rem;
  font-size: 14px;
  background-color: var(--color-background);
  // cursor: url("./assets/images/cursor.png"), default;
}

a {
  // cursor: url("./assets/images/cursor-hover.png"), url("./assets/images/cursor.png"), default !important;
}
