.tag {
    display: inline-block;
  padding: 0.2rem 0.8rem;
  width: fit-content;
  margin: 1rem;
  border-radius: 5px;
  border: 1px solid var(--color-border);
  font-family: var(--font-bold, --font-default);
  background-color: var(--color-nav-background-active);
  color: var(--color-nav-text-active);
  font-weight: bold;
  margin-left: unset;
  &:hover {
    background-color: var(--color-background-btn-hover);
    color: var(--color-link);
  }
}
